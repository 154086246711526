<template>
  <Dialog style="width:70%" v-model:visible="show" :header="activity.id ? '编辑活动':'发布活动'" @hide="hide">

    <div class="p-grid p-fluid">
      <div class="p-field p-col-12 p-md-6">
        <label>活动名称：</label>
        <InputText @blur="change1" v-model="activity.name" placeholder="请输入活动名称"/>
        <span class="change">{{ mas }}</span>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label>所属行业：</label>
        <MultiSelect v-model="industryList" :options="industry" optionLabel="name" placeholder="所属行业"
                     option-value="name" display="chip"/>
        <span class="change">{{ mas1 }}</span>
      </div>
      <div class="p-field p-col-12  p-md-6">
        <label>活动时间：</label>
        <Calendar id="basic" v-model="activity.time" :show-time="true" date-format="yy-mm-dd"/>
        <span class="change">{{ mas2 }}</span>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label>关联诉求：</label>
        <Dropdown @blur="change3" v-model="activity.demandsId" :options="demands" optionLabel="title" placeholder="选择诉求"
                  option-value="id" showClear="true"/>
        <span class="change">{{ mas3 }}</span>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label>活动地点：</label>
        <InputText @blur="change4" v-model="activity.addr" placeholder="请输入活动地点"/>
        <span class="change">{{ mas4 }}</span>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label>活动类别：</label>
        <Dropdown @blur="change5" v-model="activity.type" :options="activeType" optionLabel="name" placeholder="活动类别"
                  option-value="name" display="chip" showClear="true"/>
        <span class="change">{{ mas5 }}</span>

      </div>
    </div>
    <div class="p-col-12">
      <div>
        <label>内容：</label>
        <div class="p-mt-2">
          <my-editor :value="activity.content" v-on:input="setContent"></my-editor>
<!--          <Editor v-model="activity.content" editorStyle="height: 320px"/>-->
        </div>
      </div>
    </div>

    <div class="p-grid p-col-12 p-mt-6 p-jc-around  ">
      <Button @click="goto()" label="取消发布" class="p-mr-2" icon="pi pi-fw pi-replay"/>
      <Button @click="submit()" label="确认发布" class="p-mr-2" icon="pi pi-fw pi-send"/>
    </div>
  </Dialog>
  <Toast/>
</template>

<script>
import myEditor from "@/views/backend/myEditor";
export default {
  components:{myEditor},
  mounted() {
    this.getData()
    this.getList()
    this.getTypeList()
  },
  name: "",
  data() {
    return {
      show: false,
      activeType: [],
      mas: null,
      mas1: null,
      mas2: null,
      mas3: null,
      mas4: null,
      mas5: null,
      fileUploadUrl: this.$global_msg.frontendUrl + "/activityRelease/upload",
      activity: {
        id: null,
        name: null,
        industry: null,
        time: null,
        demandsId: null,
        content: null,
        addr: null,
        type: null,
        template: null
      },
      industryList:[],
      displayBasic: false,
      industry: [],
      demands: [],
      items: [
        {label: '活动管理', to: '/activityList'},
        {label: '活动发布', to: '/activityManagement'}
      ],
    }
  },

  methods: {
    setContent(val) {this.activity.content=val},
    init(data) {
      if (data.id) {

        let industryList  = data.industry
        if(industryList!=null&&industryList!=''){
          this.industryList=industryList.split(',')
        }
        data.time = new Date(data.time).toLocaleString()
        this.activity = data
      }
      this.show = true
    },
    hide() {
      this.show = false;
      this.$emit('close', true)
    },
    getList() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
        let list = res.data.myValue.split(",");
        /*console.log(res.data.myValue)*/
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }
        this.industry = resList;
        console.log(resList)
      })
    },
    getTypeList() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'huodongleibie'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }

        this.activeType = resList;
      })
    },

    change1(e) {
      let b = e.target.value.replace(/^ +| +$/g, '')
      if (b != e.target.value) {
        this.mas = "您输入格式有误，请重新输入"
      } else {
        this.mas = null;
      }
    },
    change3() {
      this.mas3 = null;
    },
    change4() {
      this.mas4 = null;
    },
    change5() {
      this.mas5 = null;
    },
    getData() {
      this.$http.get('/demands/list').then((res) => {
        for(let i = 0; i<res.data.length;i++){
          res.data[i].title = res.data[i].title+'('+res.data[i].companiesName+')'
        }
        this.demands = res.data
      })
    },
    getBackendUrl() {
      return window['frontendUrl'];
    },

    openBasic() {
      this.displayBasic = true;
    },
    closeBasic() {
      this.displayBasic = false;
    },
    onUpload() {
      this.$toast.add({severity: 'info', summary: '成功', detail: '文件上传成功', life: 3000});
    },
    submit() {


      let list = this.industryList
      let str = ''
      for (let i = 0; i < list.length; i++) {
        if (i < list.length-1) {
          str = str + list[i] + ','
        } else {
          str = str + list[i]
        }
      }
      this.activity.industry = str;

      (this.activity.name == null | this.activity.name == "") ? this.mas = "必填项，请输入" : this.mas = null;
      (this.activity.industry == null | this.activity.industry == "") ? this.mas1 = "必填项，请重新选择" : this.mas1 = null;
      (this.activity.time == null | this.activity.time == "") ? this.mas2 = "必填项，请重新选择" : this.mas2 = null;
      (this.activity.demandsId == null | this.activity.demandsId == "") ? this.mas3 = "必填项，请重新选择" : this.mas3 = null;
      (this.activity.addr == null | this.activity.addr == "") ? this.mas4 = "必填项，请输入" : this.mas4 = null;
      (this.activity.type == null | this.activity.type == "") ? this.mas5 = "必填项，请输入" : this.mas5 = null;
      if (this.activity.name != null & this.activity.name != ""
          & this.activity.industry != null & this.activity.industry != ""
          & this.activity.time != null & this.activity.time != ""
          & this.activity.demandsId != null & this.activity.demandsId != ""
          & this.activity.addr != null & this.activity.addr != ""
          & this.activity.type != null & this.activity.type != "") {

        this.activity.time= new Date(this.activity.time)
        this.$http.post('/activityRelease/release', this.activity).then(() => {
          this.$toast.add({
            severity: 'success', summary: '成功', detail: '活动发布成功！', life: 3000
          })
          this.$emit('close')
        })
      }
    },
    goto() {
      this.show = false,
          this.mas = null;
      this.mas1 = null;
      this.mas2 = null;
      this.mas3 = null;
      this.mas4 = null;
      this.mas5 = null;
    }
  }
}
</script>

<style scoped>
.change {
  color: red;
}

</style>
